import gql from 'graphql-tag'

import { HCP_NEW_SERF } from '../Hcp/Hcp'

export const CREATE_NEW_SERF = gql`
  mutation createSerf($hcp: SerfCreationHcpInput!, $serf: SerfCreationInput!) {
    createSerf(hcp: $hcp, serf: $serf) {
      ...HcpNewSerf
      scientificExchangeRequestForm {
        expirationDate
      }
    }
  }
  ${HCP_NEW_SERF}
`
