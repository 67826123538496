<template>
  <div>
    <v-card v-if="!wrongToken" flat>
      <v-container v-if="!notEditable" fluid class="mb-5 serf-signed relative">
        <v-btn v-if="!isHcpView" @click="$emit('input', false)" class="v-btn--absolute" dark text>
          <v-icon large left>mdi-chevron-left</v-icon> {{ $t('serfApp.backButton') }}
        </v-btn>

        <div class="text-center subtitle-1 white--text">{{ $t('edition-mode') }}</div>

        <v-container class="text-center white--text">
          <v-row justify="center">
            <v-btn
              @click="openSaveModal"
              depressed
              :disabled="!this.isSigned"
              color="white"
              min-width="200"
              class="mx-3 my-1 primary--text"
            >
              {{ $t`save` }}
            </v-btn>
            <base-confirm-modal
              v-if="!isHcpView"
              :title="$t`sure-to-cancel-serf`"
              :text="$t`sure-to-cancel-serf-content`"
              v-model="modalCancelSerf"
              @confirm="$emit('input', false)"
            />
            <v-btn
              @click="modalCancelSerf = true"
              depressed
              color="white"
              min-width="200"
              class="mx-3 my-1 primary--text"
            >
              {{ $t`cancel` }}
            </v-btn>
          </v-row>
        </v-container>
      </v-container>

      <v-container style="max-width: 900px">
        <v-card class="px-12 pt-12 pb-5">
          <v-row align="center" justify="center">
            <v-img src="@/assets/biogen-logo.svg" max-width="120" class="mb-8" />
          </v-row>
          <div class="headline text-uppercase font-weight-bold text-center">
            {{ $t('serf-demand-title-1') }}
          </div>
          <div class="headline text-uppercase font-weight-bold text-center">
            {{ $t('serf-demand-title-2') }}
          </div>
          <div class="caption font-weight-regular font-italic text-center mb-8">
            {{ isDefaultSerfType ? $t('serf-demand-subtitle-default') : $t('serf-demand-subtitle-bbu') }}
          </div>
          <p class="mb-5 body-2 font-weight-light text-left">{{ $t('serf-demand-legal-info') }}</p>

          <div class="subtitle-1">{{ $t('contact-information') }}</div>
          <v-divider class="mb-5"></v-divider>

          <form>
            <v-row class="body-2 grey--text">
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="hcp.lastName"
                  :disabled="notEditable"
                  :label="$t`lastName`"
                  v-validate="'required'"
                  data-vv-name="lastName"
                  :error-messages="errors.collect('lastName')"
                  type="text"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="hcp.firstName"
                  :disabled="notEditable"
                  :label="$t`firstName`"
                  v-validate="'required'"
                  data-vv-name="firstName"
                  :error-messages="errors.collect('firstName')"
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row class="mt-5 body-2 grey--text">
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="hcp.postalCode"
                  :disabled="notEditable"
                  :label="$t`postal-code`"
                  v-validate="'required|min:2|max:5'"
                  data-vv-name="postalCode"
                  :error-messages="errors.collect('postalCode')"
                  type="number"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="hcp.city"
                  :disabled="notEditable"
                  :label="$t`city`"
                  v-validate="'required'"
                  data-vv-name="city"
                  :error-messages="errors.collect('city')"
                  type="text"
                />
              </v-col>
            </v-row>

            <!-- @todo - doesn't show anymore until they are updated -->
            <div v-for="(serfGroup, serfGroupKey) in serfGroups" :key="`serf-${serfGroupKey}`">
              <v-divider class="mb-5" />
              <div class="subtitle-1 mt-8">{{ $t(serfGroup.label) }}</div>
              <v-checkbox
                v-if="getTypeFields(serfGroup.fields, fieldType.BOOLEAN).length > 1"
                :value="
                  getTypeFields(serfGroup.fields, fieldType.BOOLEAN).filter(
                    field => serf[field.fieldFullName] === false
                  ).length === 0
                "
                :label="$t('check-all')"
                :disabled="notEditable"
                color="serf-signed"
                hide-details
                @change="
                  isChecked => {
                    getTypeFields(serfGroup.fields, fieldType.BOOLEAN).map(field => {
                      serf[field.fieldFullName] = !!isChecked
                    })
                  }
                "
              />
              <div v-for="(serfField, i) in serfGroup.fields" :key="`serf-${serfGroupKey}-field-${i}`">
                <v-checkbox
                  v-if="serfField.fieldType === fieldType.BOOLEAN"
                  v-model="serf[serfField.fieldFullName]"
                  :label="$t(serfField.label)"
                  :disabled="notEditable"
                  color="serf-signed"
                  hide-details
                />
                <div v-else-if="serfField.fieldType === fieldType.STRING">
                  <div v-if="(notEditable && serf[serfField.fieldFullName]) || !notEditable" class="mt-8">
                    {{ $t(serfField.label) }}
                  </div>
                  <v-row>
                    <v-col v-if="!notEditable" cols="12" md="6">
                      <v-textarea v-model="serf[serfField.fieldFullName]" rows="3" auto-grow outlined />
                    </v-col>
                    <v-col v-else>
                      <p class="text-center grey--text">{{ serf[serfField.fieldFullName] }}</p>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </form>

          <template v-if="!notEditable">
            <v-row justify="center">
              <v-dialog v-model="modalSignatureSerf" max-width="1200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!isValid"
                    class="mb-8 px-6 py-2 white--text"
                    color="serf-signed"
                    depressed
                  >
                    <v-icon small left>mdi-fountain-pen</v-icon>{{ $t('serfForm.sign') }}
                  </v-btn>
                </template>
                <v-card>
                  <Consent
                    v-if="modalSignatureSerf"
                    @signature="v => (serf.signature = v)"
                    @scan="v => (serf.scan = v)"
                    @save="openSaveModal"
                    :noScan="isHcpView"
                    :value="serf.signature || serf.scan"
                  >
                    <template slot="tabs">
                      <v-tab @click.stop="modalSignatureSerf = false" class="close-btn">
                        <v-icon>mdi-close</v-icon>
                      </v-tab>
                    </template>
                    <template slot="description">
                      <div v-html="$t('legal-consent')" class="mb-5 caption font-weight-light" />
                    </template>
                  </Consent>
                </v-card>
              </v-dialog>
            </v-row>
            <p v-html="$t('serf-demand-legal-footer')" class="footer-paragraph font-weight-thin" />
          </template>
        </v-card>
      </v-container>
    </v-card>

    <v-container v-else>
      <v-row justify="center" class="pt-12">
        <v-card elevation="3" max-width="500" class="pa-12 text-center">
          <v-card-title>{{ $t('serfForm.wrongToken') }}</v-card-title>
          <v-icon color="error" x-large>mdi-close</v-icon>
        </v-card>
      </v-row>
    </v-container>

    <base-confirm-modal
      :title="$t`sure-to-save`"
      :text="$t`you-can-revoke-serf`"
      :loaded="saved || Boolean(serf.scan)"
      v-model="modalSaveSerf"
      @confirm="preSaveSerf"
    />
    <base-confirm-modal
      v-if="modalSignatureDate"
      v-model="modalSignatureDate"
      @confirm="saveSerf"
      :disableValidate="!serf.signatureDate"
      :loaded="saved"
      persitent
      :title="$t('serfForm.modalSignatureDateTitle')"
    >
      <template v-slot:text>
        <div class="d-flex justify-center my-5">
          <v-date-picker v-model="serf.signatureDate" full-width :max="new Date().toISOString()" />
        </div>
      </template>
    </base-confirm-modal>
    <v-overlay :value="overlay">
      <v-slide-y-transition hide-on-leave>
        <v-card light max-width="100%" class="text-center px-10 py-5">
          <v-card-title>{{ isHcpView ? $t`serf-was-send` : $t`serf-was-validate` }}</v-card-title>
          <v-icon color="green" class="my-6" style="font-size: 80px">mdi-check-circle-outline</v-icon>
          <v-card-actions v-if="!isHcpView">
            <v-btn @click="reloadPage" text color="primary" exact class="mx-auto">{{ $t('back-to-list') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-slide-y-transition>
    </v-overlay>
    <v-overlay :value="isHcpView && !itemByToken && !wrongToken">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import _get from 'lodash/get'

import shared from '@/const/shared'
import Consent from '../../components/serf/Consent'
import { CREATE_NEW_SERF } from '@/graphql/Serf/CreateSerf'
import { RENEW_SERF } from '@/graphql/Serf/RenewSerf'
import { RENEW_SERF_WITH_TOKEN } from '@/graphql/Serf/RenewSerfWithToken'
import { GET_SERF_TYPE_BY_TOKEN, GET_VEEVA_USER_BY_TOKEN } from '@/graphql/VeevaUsers/GetVeevaUserByToken'

export default {
  name: 'Serf_Form',

  components: { Consent },

  $_veeValidate: {
    validator: 'new'
  },

  props: {
    item: Object,
    notEditable: {
      type: Boolean,
      default: false
    },
    viewTeam: {
      type: Boolean,
      default: false
    }
  },

  apollo: {
    hcpSerfType: {
      query: GET_SERF_TYPE_BY_TOKEN,
      variables() {
        return {
          healthCareProfessionalUuid: this.healthCareProfessionalUuid,
          token: this.$route.query.token,
          defaultValue: this.defaultSerfType
        }
      },
      error(error) {
        this.notEditable = true
      }
    },
    itemByToken: {
      query: GET_VEEVA_USER_BY_TOKEN,
      variables() {
        return { token: this.$route.query.token }
      },
      update: data => data.getVeevaUserByToken,
      result({ data: { getVeevaUserByToken: user } }) {
        this.hcp.city = _get(user, 'healthCareProfessional.city', '')
        this.hcp.firstName = _get(user, 'healthCareProfessional.firstName', '')
        this.hcp.lastName = _get(user, 'healthCareProfessional.lastName', '')
        this.hcp.postalCode = _get(user, 'healthCareProfessional.postalCode', '')
      },
      skip() {
        return Boolean(!this.isHcpView) || this.viewTeam
      },
      error(error) {
        this.wrongToken = true
      }
    }
  },

  data() {
    const { item } = this

    return {
      fieldType: shared.fieldType,
      hcp: {
        city: _get(item, 'city', ''),
        firstName: _get(item, 'firstName', ''),
        lastName: _get(item, 'lastName', ''),
        postalCode: _get(item, 'postalCode', '')
      },
      serf: {
        alzheimer: _get(item, 'alzheimer', false),
        scleroseEnPlaques: _get(item, 'scleroseEnPlaques', false),
        amyotrophieSpinale: _get(item, 'amyotrophieSpinale', false),
        therapeutiquesBiogen: _get(item, 'therapeutiquesBiogen', false),
        strategieEtGuidelines: _get(item, 'strategieEtGuidelines', false),
        etudesReellesFr: _get(item, 'etudesReellesFr', false),
        etudesReellesInter: _get(item, 'etudesReellesInter', false),
        pipelineEtResultatClinique: _get(item, 'pipelineEtResultatClinique', false),
        biomarqueur: _get(item, 'biomarqueur', false),
        cognition: _get(item, 'cognition', false),
        diagnostic: _get(item, 'diagnostic', false),
        imagerie: _get(item, 'imagerie', false),
        parcoursDeSoins: _get(item, 'parcoursDeSoins', false),
        inflammatoireIntestinBbu: _get(item, 'inflammatoireIntestinBbu', false),
        rhumatismeBbu: _get(item, 'rhumatismeBbu', false),
        psoriasisEtHidrosadeniteBbu: _get(item, 'psoriasisEtHidrosadeniteBbu', false),
        pathologiesOphtalmiquesBbu: _get(item, 'pathologiesOphtalmiquesBbu', false),
        therapeutiquesBiogenBbu: _get(item, 'therapeutiquesBiogenBbu', false),
        strategieEtGuidelinesBbu: _get(item, 'strategieEtGuidelinesBbu', false),
        etudesReellesBbu: _get(item, 'etudesReellesBbu', false),
        pipelineEtResultatCliniqueBbu: _get(item, 'pipelineEtResultatCliniqueBbu', false),
        caracteristiquesBiosimilaireBbu: _get(item, 'caracteristiquesBiosimilaireBbu', false),
        biomarqueurBbu: _get(item, 'biomarqueurBbu', false),
        patientReportedOutcomesBbu: _get(item, 'patientReportedOutcomesBbu', false),
        parcoursDeSoinsBbu: _get(item, 'parcoursDeSoinsBbu', false),
        therapeuticDrugMonitoringEtOptiomisationBbu: _get(item, 'therapeuticDrugMonitoringEtOptiomisationBbu', false),
        effetNoceboBbu: _get(item, 'effetNoceboBbu', false),
        scan: '',
        signature: ''
      },
      isInit: false,
      isValid: false,
      overlay: false,
      saved: false,
      modalSignatureSerf: false,
      modalCancelSerf: false,
      modalSaveSerf: false,
      modalSignatureDate: false,
      dictionary: {
        custom: {
          firstName: {
            required: () => this.$t`error-require-first-name`
          },
          lastName: {
            required: () => this.$t`error-require-name`
          },
          postalCode: {
            required: () => 'Le code postal est requis',
            min: this.$t('error-require-minimal-postal-code', { value: 2 }),
            max: this.$t('error-require-maximal-postal-code', { value: 5 })
          },
          city: {
            required: () => this.$t`error-require-city`
          }
        }
      },

      // HCP View
      itemByToken: null,
      wrongToken: false
    }
  },

  computed: {
    isSigned() {
      return this.serf.signature || this.serf.scan
    },
    healthCareProfessionalUuid() {
      if (this.itemByToken) {
        return this.itemByToken.healthCareProfessional.healthCareProfessionalUuid
      } else if (this.item && this.item.healthCareProfessionalUuid) {
        return this.item.healthCareProfessionalUuid
      } else {
        // @todo fetch healthCareProfessionalUuid from this.item.veevaId ??
        return null
      }
    },
    defaultSerfType() {
      switch (this.$route.path.split('/')[1]) {
        case 'serf_bbu':
          return shared.serfType.BBU
        case 'serf':
        default:
          return shared.serfType.DEFAULT
      }
    },
    serfGroups() {
      // @todo - enable it back when it is updated
      return {}

      // eslint-disable-next-line no-unreachable
      if (!this.hcpSerfType) {
        return {}
      }
      const groups = shared.serfGroups[this.hcpSerfType]
      this.initSerfValues(groups)
      return groups
    },
    isHcpView() {
      return !this.item
    },
    isDefaultSerfType() {
      if (!this.hcpSerfType) {
        return true
      }

      return this.hcpSerfType === shared.serfType.DEFAULT
    },
    haveNecessaryFieldsFill() {
      for (const property in this.hcp) {
        if (!this.hcp[property]) return false
      }

      return true

      // @todo - to enable back when it has been update
      // eslint-disable-next-line no-unreachable
      return (
        this.isInit &&
        (this.serfGroups && this.serfGroups.bu
          ? this.serfGroups.bu.fields.filter(field => this.serf[field.fieldFullName]).length !== 0
          : true)
      )
    },
    mutationComputed() {
      if (this.itemByToken) return RENEW_SERF_WITH_TOKEN
      if (this.item && this.item.healthCareProfessionalUuid) return RENEW_SERF
      return CREATE_NEW_SERF
    }
  },

  mounted() {
    this.$validator.localize('fr', this.dictionary)
  },

  watch: {
    hcp: {
      handler() {
        this.validate()
      },
      deep: true,
      immediate: true
    },
    serf: {
      handler() {
        this.validate()
      },
      deep: true
    }
  },

  methods: {
    openSaveModal() {
      if (this.isSigned) {
        this.modalSaveSerf = true
      }
    },
    initSerfValues(groups) {
      if (!this.isInit) {
        for (let group of Object.values(groups)) {
          for (let field of group.fields) {
            this.serf[field.fieldFullName] = _get(
              this.item,
              field.fieldFullName,
              field.fieldType === shared.fieldType.BOOLEAN ? false : ''
            )
          }
        }

        this.isInit = true
        this.validate()
      }
    },
    getTypeFields(fields, type) {
      return fields.filter(field => field.fieldType === type)
    },
    preSaveSerf() {
      if (this.serf.scan && !this.isHcpView) {
        this.modalSaveSerf = false
        this.modalSignatureDate = true
        return
      }

      this.saveSerf()
    },
    reloadPage() {
      location.reload()
    },
    async saveSerf() {
      const variables = {
        hcp: this.hcp,
        serf: {
          ...this.serf,
          serfType: this.hcpSerfType
        }
      }

      if (variables.serf.signatureDate) {
        variables.serf.signatureDate = new Date(variables.serf.signatureDate).toISOString()
      }

      if (this.itemByToken) {
        variables.hcp.healthCareProfessionalUuid = this.itemByToken.healthCareProfessional.healthCareProfessionalUuid
        variables.token = this.$route.query.token
      } else if (this.item.healthCareProfessionalUuid) {
        variables.hcp.healthCareProfessionalUuid = this.item.healthCareProfessionalUuid
      } else {
        variables.hcp.veevaId = this.item.veevaId
      }

      await this.$apollo.mutate({
        mutation: this.mutationComputed,
        variables
      })

      this.modalSignatureDate = false
      this.modalSignatureSerf = false
      this.modalSaveSerf = false
      this.saved = true
      this.overlay = true
    },
    async validate() {
      if (!this.haveNecessaryFieldsFill) {
        this.isValid = false
      } else {
        this.isValid = await this.$validator.validateAll()
      }
    }
  }
}
</script>

<style lang="scss">
.footer-paragraph {
  font-size: 11px;
}

.close-btn {
  max-width: 30px !important;
}
</style>
