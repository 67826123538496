import gql from 'graphql-tag'

import { HCP_NEW_SERF } from '../Hcp/Hcp'

export const RENEW_SERF = gql`
  mutation RenewSerf($hcp: SerfRenewHcpInput!, $serf: SerfCreationInput!) {
    renewSerf(hcp: $hcp, serf: $serf) {
      ...HcpNewSerf
      scientificExchangeRequestForm {
        expirationDate
      }
    }
  }
  ${HCP_NEW_SERF}
`
