import gql from 'graphql-tag'

export const GET_SERF_TYPE_BY_TOKEN = gql`
  query getSerfTypeByToken($healthCareProfessionalUuid: GUID, $token: String, $defaultValue: String) {
    hcpSerfType(healthCareProfessionalUuid: $healthCareProfessionalUuid, token: $token, defaultValue: $defaultValue)
  }
`

export const GET_VEEVA_USER_BY_TOKEN = gql`
  query getVeevaUserByToken($token: String!) {
    getVeevaUserByToken(token: $token) {
      veevaId
      firstName
      lastName
      email
      veevaId
      postalCodes
      cities
      rppsNumber
      healthCareProfessional {
        healthCareProfessionalUuid
        firstName
        lastName
        postalCode
        city
        hasRefused
        refusedDate
        emailAddress
        emailConsent
        specialty
        targetMs
        targetBbu
        targetSma
        zone {
          label
        }
        scientificExchangeRequestForm {
          signatureDate
          status
          expirationDate

          alzheimer
          amyotrophieSpinale
          autres
          biomarqueur
          cognition
          diagnostic
          etudesReellesFr
          etudesReellesInter
          imagerie
          parcoursDeSoins
          pipelineEtResultatClinique
          scleroseEnPlaques
          strategieEtGuidelines
          therapeutiquesBiogen

          autresBbu
          biomarqueurBbu
          caracteristiquesBiosimilaireBbu
          effetNoceboBbu
          etudesReellesBbu
          inflammatoireIntestinBbu
          parcoursDeSoinsBbu
          pathologiesOphtalmiquesBbu
          patientReportedOutcomesBbu
          pipelineEtResultatCliniqueBbu
          psoriasisEtHidrosadeniteBbu
          rhumatismeBbu
          strategieEtGuidelinesBbu
          therapeuticDrugMonitoringEtOptiomisationBbu
          therapeutiquesBiogenBbu
        }
      }
    }
  }
`
